import { useEffect } from 'react'

import isClient from 'utils/envs/isClient'

// import Layout from 'layouts/LandingPage'
// import cmsData from 'utils/cmsData'
// import About from './components/About'
// import Faq from './components/Faq'
// import Hero from './components/Hero'
// import Steps from './components/Steps'
// import { useObserveForAnimations, useResetStore } from './hooks'
import './landing.styl'

const Landing = () => {
  // useResetStore()
  // useObserveForAnimations()
  useEffect(() => {
    isClient() &&
      window.location.assign(
        'https://emprestimo.itau.com.br/?utm_source=redirect-iti'
      )
  }, [])

  return (
    <></>
    // <Layout>
    //   <Hero {...cmsData.hero} />
    //   <About {...cmsData.about} />
    //   <Steps {...cmsData.steps} />
    //   <Faq {...cmsData.faq} />
    // </Layout>
  )
}

export default Landing
